/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 16:34:08
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-25 11:44:52
 */
import { wVerifyNum } from '@/plugins/verifier'
import HandleData from '@/service/Agent'
export default {
  name: 'AgentMax',
  data () {
    return {
      WAgentMaxShow: false,
      form: {
        max_custom_num: ''
      },
      rules: {
        max_custom_num: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写可开通客户账户数')
            }
            if (!wVerifyNum(val)) {
              res('请填写正整数')
            }
            const max = 100000
            if (Number(val) > max) {
              res('开户额度不能超过' + max)
            }
            res()
          },
          trigger: 'blur',
          required: true
        }]
      }
    }
  },
  methods: {
    show () {
      this.WAgentMaxShow = true
    },
    hide () {
      this.WAgentMaxShow = false
    },
    cancel () {
      this.hide()
    },
    submit () {
      this.$refs.form.validate(async v => {
        if (v) {
          const payload = new HandleData(this.form).agentMax()
          const data = await this.$wPost('/admin/agent/update_max_num.do', payload)
          if (data === true) {
            this.$wToast.success('修改成功')
            this.hide()
            this.$emit('add')
          } else {
            this.$wToast.error('修改失败')
          }
          this.$refs.submitButton.updatedLoading(false)
        } else {
          this.$refs.submitButton.updatedLoading(false)
        }
      })
    },
    close () {
      this.$refs.form.clearValidate()
      this.updatedForm({
        max_custom_num: ''
      })
    },
    async updatedForm (payload) {
      this.form = Object.assign({}, payload)
      // for (const props in payload) {
      //   await this.$set(this.form, props, payload[props])
      // }
      console.log(this.form)
    }
  }
}
