/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 16:34:14
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-20 16:42:22
 */
import dialog from './dialog.vue'
export default dialog
